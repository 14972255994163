<template>
  <div>
    <div class="row no-gutters justify-content-between mb-1">
      <div class="col-sm-auto flex-grow-0">
        <h3 class="mb-0 text-left text-uppercase">{{ companyDetail.name? companyDetail.name  + ' AUDIT RECORD' : ''}}</h3>
      </div>
      <div class="col-sm-auto">
        <button class="e-btn e-btn-green force-white-all" data-toggle="modal" data-target="#ScheduleNewCompanyAuditModal">
          <font-awesome-icon icon="plus-square" style="width: 25px"/>
          <small class="font-weight-bold px-2">
            NEW COMPANY AUDIT RECORD
          </small>
        </button>
        <button class="btn btn-xs btn-dark font-weight-bold e-text-white ml-1" type="button" @click="redirectToAuditRecordList">
          <font-awesome-icon icon="chevron-left" class="e-text-black"/> Go Back
        </button>
      </div>
    </div>

    <div class="row no-gutters align-items-center justify-content-between">
      <div class="col-sm-auto pb-2 mr-2">
        <nav aria-label="...">
          <ul class="pagination text-smallest mb-0 pb-0">
            <li class="page-item" :class="{ 'disabled': pagination.currentPage === 1}">
              <button class="page-link" @click="setPage(1)">First</button>
            </li>

            <li v-for="page in paginationPages" :key="page" class="page-item" :class="{ 'disabled': page === '...', 'active': page === pagination.currentPage }">
              <button class="page-link" @click="setPage(page)">{{ page }}</button>
            </li>

            <li class="page-item" :class="{ 'disabled': pagination.currentPage === pagination.lastPage}">
              <button class="page-link" @click="setPage(pagination.lastPage)">Last</button>
            </li>
          </ul>
        </nav>
      </div>
      <div class="col-sm-auto pb-2">
        <div class="input-group input-group-sm fa-w-4 float-right">
          <div class="input-group-prepend">
            <input v-if="$route.name !== 'VesselInternalAuditHistory'" type="text" name="auditRecordSearch" class="form-control form-control-sm" placeholder="Search Audit Record" v-model="filters.query">
            <label class="input-group-text input-group-sm bg-white small border-0" for="rowCount"># of Row(s):</label>
          </div>
          <select class="form-control form-control-sm rounded-left" id="rowCount" v-model="filters.count">
            <option value="30">30</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="10000">All</option>
          </select>
        </div>
      </div>
    </div>

    <div class="row no-gutters justify-content-between mb-1">
      <div class="col-sm-12">
        <table class="small e-tbl text-center">
          <thead class="bg-white" style="position: sticky; top: 0; z-index: 2">
          <tr>
            <th class="align-middle" style="width: 1em" scope="col">#</th>
            <th class="align-middle" scope="col">TYPE OF AUDIT</th>
            <th class="align-middle" scope="col">Person Responsible</th>
            <th class="align-middle" scope="col">AUDIT DATE</th>
            <th class="align-middle" scope="col">APPOINTMENT DATE</th>
            <th class="align-middle" scope="col">AUDIT PLACE</th>
            <th class="align-middle" scope="col">AUDITOR(S)</th>
            <th class="align-middle" scope="col">STATUS</th>
            <th class="align-middle" scope="col">INTERNAL AUDIT SCORE</th>
            <th class="align-middle" scope="col">EXTENSION</th>
            <th class="align-middle" scope="col">ACTION</th>
          </tr>
          </thead>
          <tbody>
          <tr v-if="loading">
            <td colspan="100%">
              <loading></loading>
            </td>
          </tr>
          <template v-else v-for="(audit_record, index) in companyAuditRecord">
            <template v-for="(type, type_index) in getRowSpanCount(audit_record)">
              <tr class="ia-vessel-list" @mouseover="highlightData(index, true)" @mouseout="highlightData(index, false)">
                <td :class="'list_' + index">{{ index + 1 }}</td>
                <td :class="'list_' + index">{{ convertAuditType3IntoType(audit_record[type].audit_type_3) }}</td>
                <td :class="'list_' + index">{{ audit_record[type].person_responsible }}</td>
                <td :class="'list_' + index">
                  {{ audit_record[type].audit_date_start.human === audit_record[type].audit_date_end.human ? audit_record[type].audit_date_end.human : audit_record[type].audit_date_start.human + ' - ' + audit_record[type].audit_date_end.human }}
                </td>
                <td :class="'list_' + index">
                  {{ audit_record[type].appointment_date.human }}
                </td>
                <td :class="'list_' + index"> {{ audit_record[type].audit_place }}</td>
                <td :class="'list_' + index">
                  <div v-for="auditor in audit_record[type].auditors" class="text-left">
                    {{ auditor.name }} <strong>{{ (auditor.is_lead === true ? ' (Lead)' : '')}}</strong>
                  </div>
                </td>
                <td :class="['bg-'+convertReportStatus(audit_record[type].report_status_id).color]"> {{ convertReportStatus(audit_record[type].report_status_id).name }}</td>
                <td :class="'list_' + index">{{ audit_record[type].audit_score }}</td>
                <td :class="'list_' + index">{{ audit_record[type].is_extended === 0 ? 'NO' : 'YES'}}</td>
                <td :class="'list_' + index" v-if="type_index === 0" :rowspan="getRowSpanCount(audit_record).length">
                  <div v-if="$route.name !== 'VesselInternalAuditHistory'" class="dropdown dropright">
                    <button
                      id="actionDropdown"
                      aria-expanded="false"
                      aria-haspopup="true"
                      class="mr-2 btn btn-sm btn-secondary force-white-all"
                      data-toggle="dropdown"
                    >
                      <font-awesome-icon icon="cogs"/>
                    </button>
                    <div aria-labelledby="actionDropdown" class="dropdown-menu py-0" style="min-height: 50px">
                      <button class="dropdown-item text-secondary font-weight-bolder"
                              @click="viewAuditRecord(audit_record)">
                        <font-awesome-icon icon="eye"/>
                        View
                      </button>
                      <button class="dropdown-item text-secondary font-weight-bolder"
                              @click="viewAuditRecordNewTab(audit_record)">
                        <font-awesome-icon icon="eye"/>
                        View on New Tab
                      </button>
                      <button class="dropdown-item text-secondary font-weight-bolder text-danger"
                              @click="deleteSelected(audit_record.id)"
                      >
                        <font-awesome-icon icon="trash" class="force-danger-all"/>
                        Delete
                      </button>
                    </div>
                  </div>
                  <button v-else class="e-btn e-btn-blue e-btn-sm e-text-white"
                          @click="viewAuditRecord(audit_record)">
                    <font-awesome-icon icon="eye"/>
                    View
                  </button>
                </td>

              </tr>
            </template>

          </template>

          </tbody>
        </table>
      </div>
    </div>
    <ScheduleCompanyAudit/>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import {vueAppMixin} from "@/mixins/vueAppMixin";
import AppLoading from "@/components/elements/AppLoading";

import _ from 'lodash';
import {permissionMixin} from "@/mixins/permissionMixins";
import {AlertService} from "@/services/AlertService";
import {PaginationMixin} from "@/mixins/PaginationMixin";
import ScheduleCompanyAudit from "@/components/modules/ia/company/ScheduleCompanyAudit.vue";
import loading from "@/components/elements/AppLoading.vue";

import CompanyAuditRecordMixin from "@/mixins/CompanyAuditRecordMixin";

export default {

  mixins: [vueAppMixin,permissionMixin,PaginationMixin, CompanyAuditRecordMixin],
  components: {loading, AppLoading, ScheduleCompanyAudit},

  name: 'CompanyAuditRecordList',
  data() {
    return {
      filters: {
        page: 1,
        count: 30,
        query : '',
        company_id : this.$route.params.company_id
      },
      loading : false,
    }
  },
  methods: {
    highlightData(rowIndex, hover) {
      const allList = $('.list_' + rowIndex);
      for (let counter = 0; counter < allList.length; counter++) {
        if (hover === true) {
          $(allList[counter]).addClass('list-hover')
        } else {
          $(allList[counter]).removeClass('list-hover')
        }
      }
    },
    redirectToAuditRecordList() {
      return this.$router.push({name: 'CompanyList'});
    },
    async viewAuditRecord(companyAuditRecord) {
      await this.$router.push(
        {
          name: 'CompanyInternalAuditReport',
          params: {
            id: companyAuditRecord.id,
            // company_id : companyAuditRecord.company_id,
            type : this.getFirstLandingType(companyAuditRecord)
          },
        },

      ).catch(() => {})
    },
    async viewAuditRecordNewTab(companyAuditRecord) {
      const route= this.$router.resolve(
        {
          name: 'CompanyInternalAuditReport',
          params: {
            id: companyAuditRecord.id,
            // company_id : companyAuditRecord.company_id,
            type : this.getFirstLandingType(companyAuditRecord)
          },
        },
      )
      window.open(route.href,'_blank')
    },
    async filterAuditRecordList() {
      this.loading = true;
      await this.getCompanyAuditRecordList(this.filters);
      this.loading = false;
    },
    async deleteSelected(auditRecordId) {
      if(await AlertService.confirmDeleteAlert('Delete Audit Record', 'Are you sure you want to delete this Audit Record?', 'Delete')){
        swal.fire({
          title:'ENTER YOUR PASSWORD',
          input: 'password',
          inputAttributes: {
            autocapitalize: 'off'
          },
          confirmButtonText:'PROCEED DELETION',
          cancelButtonText: 'CANCEL',
          showCancelButton:true,
          allowOutsideClick:false,
          allowEscapeKey:false,
          allowEnterKey:false,
          confirmButtonColor:'#dc3545',
          showLoaderOnConfirm:true,
          preConfirm:async (inputValue)=> {
            let response = await this.deleteCompanyAuditRecord({audit_record_id: auditRecordId, password: inputValue});
            if (typeof response === 'boolean' && response) {
              return response;
            } else {
              if (response.hasOwnProperty('message') && !response.hasOwnProperty('errors')) {
                swal.showValidationMessage(response.message);
              }
              else{
                swal.showValidationMessage(response.errors.password.join(','));
              }
            }
          }
        })
          .then(async (result)=>{
            if(result.isConfirmed){
              await this.successAlert('AUDIT RECORD DELETED SUCCESSFULLY','DELETE AUDIT RECORD');
              await this.filterAuditRecordList();
            }
          });
      }
    },
    async setPage(page) {
      this.pagination.currentPage = page;
      this.filters.page = page
      await this.getCompanyAuditRecordList(this.filters);
    },
  },
  async created() {
    this.searchAuditRecord = _.debounce(this.filterAuditRecordList, 750);
    await this.getCompanyDetail(this.$route.params.company_id);
    await this.getCompanyAuditRecordList(this.filters);
  },
  watch : {
    async 'filters.count'(count) {
      this.filters.count = count
      this.filters.page = 1
      await this.filterAuditRecordList();
    },
    async 'filters.query'(query){
      this.filters.query = query;
      this.searchAuditRecord();
    }
  }
}
</script>

<style scoped lang="scss">
td {
  padding: 3px;
}
</style>
