<script>
import PopperModal from "@/components/elements/PopperModal.vue";
import MultiSelectWithSearch from "@/components/MultiSelectWithSearch.vue";
import SearchDropdown from "@/components/modules/ia/SearchDropdown.vue";
import {AlertService} from "@/services/AlertService";
import {DataService} from "@/services/DataService";
import DateMixin from "@/mixins/DateMixin";
import AuditorMixin from "@/mixins/AuditorMixin";
import ObjectMixin from "@/mixins/ObjectMixin";
import {mapActions, mapGetters} from "vuex";
import DatePicker from "vue2-datepicker";
import 'vue2-datepicker/index.css';
import SelectWithSearch from "@/components/common/SelectWithSearch.vue";
import CompanyAuditRecordMixin from "@/mixins/CompanyAuditRecordMixin";


export default {
  name: "ScheduleCompanyAudit",
  data() {
    return {
      audit_type_1 : [],
      newCompanyAudit : {
        sms: null,
        isps: null,
        cyber : null
      },
      activeAuditType : 'sms',
      keysToAssign : [
        'appointment_date',
        'audit_country_id',
        'audit_date',
        'audit_port_id',
        'audit_type_2',
        'audit_type_3',
        'extension',
        'extension_date',
        'lead_ids',
        'members_ids',
      ]
    }
  },
  components: {DatePicker, SearchDropdown, MultiSelectWithSearch, PopperModal, SelectWithSearch},
  mixins:[DateMixin, AuditorMixin, ObjectMixin, CompanyAuditRecordMixin],
  methods: {
    setAuditTypeTwo(type) {
      this.newCompanyAudit[this.activeAuditType] = DataService.pushOrRemoveInData(this.newCompanyAudit[this.activeAuditType], 'audit_type_2', type);
    },

    setAuditTypeThree(type) {
      this.newCompanyAudit[this.activeAuditType] = DataService.pushOrRemoveInData(this.newCompanyAudit[this.activeAuditType], 'audit_type_3', type);
    },
    setAuditTypeOne(indexType, type) {
      if (this.audit_main_types[0].enabled === false && this.audit_main_types[1].enabled === false && this.audit_main_types[2].enabled === false) {
        this.activeAuditType = type;
      }
      this.audit_main_types[indexType].enabled = !this.audit_main_types[indexType].enabled;
      if (this.audit_main_types[indexType].type === this.activeAuditType) {
        if (indexType === 0 && this.audit_main_types[indexType].enabled === false) {
          if (this.audit_main_types[1].enabled === true) {
            this.activeAuditType = 'isps';
          } else if (this.audit_main_types[2].enabled === true) {
            this.activeAuditType = 'cyber';
          }
        } else if (indexType === 1 && this.audit_main_types[indexType].enabled === false) {
          if (this.audit_main_types[0].enabled === true) {
            this.activeAuditType = 'sms';
          } else if (this.audit_main_types[2].enabled === true) {
            this.activeAuditType = 'cyber';
          }
        } else if (indexType === 2 && this.audit_main_types[indexType].enabled === false) {
          if (this.audit_main_types[0].enabled === true) {
            this.activeAuditType = 'sms';
          } else if (this.audit_main_types[1].enabled === true) {
            this.activeAuditType = 'isps';
          }
        }
      }
      this.newCompanyAudit[type] = this.addTypeInitialValues(type);
    },
    setActiveAuditType(indexType) {
      if (this.audit_main_types[indexType].enabled === true) {
        if (indexType === 0) {
          this.activeAuditType = 'sms';
        } else if (indexType === 1) {
          this.activeAuditType = 'isps';
        } else {
          this.activeAuditType = 'cyber';
        }
      }
    },
    async createNewAuditRecord() {
      let newRecord = this.getDataBasedOnActiveTypes(this.newCompanyAudit);
      newRecord.company_id = parseInt(this.$route.params.company_id);

      let createRequest = await this.createCompanyAuditRecord(newRecord);
      if (createRequest.result === false) {
        this.activeAuditType = this.getTypeThatHasAnError(Object.keys(createRequest.errors));
        return AlertService.errorAlert(createRequest.message, 'SAVING NEW COMPANY AUDIT RECORD');
      }

      this.initializeData();
      this.resetAuditMainTypes();

      $('#ScheduleNewVesselAuditModal').modal('hide');

      let firstLandingType = this.getFirstLandingType(newRecord);
      successAlert.fire({
        title: `ADDING OF NEW COMPANY AUDIT RECORD`,
        showConfirmButton: true,
        allowOutsideClick:false,
        allowEnterKey:false,
        allowEscapeKey:false,
        timer: 1500,
        scrollbarPadding: false
      }).then(async (result)=>{
        if(result.isConfirmed){
          await this.$router.push({ name: 'CompanyInternalAuditReport', params: {
              id: createRequest.data.id,
              company_id: createRequest.data.company_id,
              type: firstLandingType
          } }).catch(() => {
          })
        }
      })

      setTimeout(() => {
        if (this.$route.name !== 'CompanyInternalAuditReport') {
          this.$router.push({name: 'CompanyInternalAuditReport', params: {
              id: createRequest.data.id,
              company_id: createRequest.data.company_id,
              type: firstLandingType
          }})
        }
      }, 2000);
    },
    async cancelAdd() {
      if (this.noSelectedAuditTypeOne === true) {
        return $('#ScheduleNewCompanyAuditModal').modal('hide');
      }
      const response = await AlertService.cancelAlert();
      if (response) {
        this.initializeData();
        this.resetAuditMainTypes();
        $('#ScheduleNewCompanyAuditModal').modal('hide');
      }
    },
    setLeadMembers(leadMemberIds) {
      this.newCompanyAudit[this.activeAuditType].lead_ids = leadMemberIds.selected.length > 0 ? leadMemberIds.selected : [];
    },
    setNonLeadMembers(nonLeadMemberIds) {
      this.newCompanyAudit[this.activeAuditType].members_ids = nonLeadMemberIds.selected.length > 0 ? nonLeadMemberIds.selected : [];
    },
    syncObjectToOtherObject(auditTypeToSync) {
      let newObject = DataService.assignObjectKeysToOtherObject(this.newCompanyAudit[auditTypeToSync], this.keysToAssign);
      this.newCompanyAudit[this.activeAuditType] = DataService.setValuesFromNewObject(this.newCompanyAudit[this.activeAuditType], newObject);
      },
    setSameAsValue(selectionIndex = null) {
      if (this.sameAsEventHandler.selection !== undefined) {
        if (this.sameAsEventHandler.selection[selectionIndex].same_as === this.newCompanyAudit[this.activeAuditType].same_in.value) {
          this.newCompanyAudit[this.activeAuditType].same_in.enabled = false;
          this.newCompanyAudit[this.activeAuditType].same_in.value = null;
        } else {
          this.newCompanyAudit[this.activeAuditType].same_in.enabled = true;
          let auditType1 = this.newCompanyAudit[this.activeAuditType].same_in.enabled === true ? this.sameAsEventHandler.selection[selectionIndex].same_as : null;
          this.newCompanyAudit[this.activeAuditType].same_in.value = auditType1;
          if (auditType1 !== null) {
            this.syncObjectToOtherObject(auditType1);
          }
        }
      } else {
        this.newCompanyAudit[this.activeAuditType].same_in.enabled = !this.newCompanyAudit[this.activeAuditType].same_in.enabled;
        let auditType1 = this.newCompanyAudit[this.activeAuditType].same_in.enabled === true ? this.sameAsEventHandler.same_as : null;
        this.newCompanyAudit[this.activeAuditType].same_in.value = auditType1;
        if (auditType1 !== null) {
          this.syncObjectToOtherObject(auditType1);
        }
      }
    },
    initializeData() {
      this.newCompanyAudit.sms = this.addTypeInitialValues('sms');
      // this.newCompanyAudit.isps = this.addTypeInitialValues('isps');
      // this.newCompanyAudit.cyber = this.addTypeInitialValues('cyber');
    }
  },
  computed: {
    ports() {
      return this.portKeys.filter(port => port.country_id === this.newCompanyAudit[this.activeAuditType]?.audit_country_id)
    },
    noSelectedAuditTypeOne() {
      let disabled = true;
      for (let counter = 0; counter < 3; counter++) {
        if (this.audit_main_types[counter].enabled === true) {
          disabled = false;
        }
      }
      return disabled;
    },
    disabledElements() {
      // return this.noSelectedAuditTypeOne || this.newCompanyAudit[this.activeAuditType].same_in.enabled === true
      return false;
    },
    // sameAsEventHandler() {
    //   if (this.audit_main_types[0].enabled === false && this.audit_main_types[1].enabled === true && this.audit_main_types[2].enabled === true && this.activeAuditType === 'cyber') {
    //     return {
    //       type      : 'cyber',
    //       text      : 'SAME AS ISPS',
    //       same_as   : 'isps'
    //     }
    //   }
    //
    //   if (this.audit_main_types[0].enabled === true && this.audit_main_types[1].enabled === false && this.audit_main_types[2].enabled === true && this.activeAuditType === 'cyber') {
    //     return {
    //       type      : 'cyber',
    //       text      : 'SAME AS SMS',
    //       same_as   : 'sms'
    //     }
    //   }
    //
    //   if (this.audit_main_types[0].enabled === true && this.audit_main_types[1].enabled === true && this.audit_main_types[2].enabled === false && this.activeAuditType === 'isps') {
    //     return {
    //       type      : 'isps',
    //       text      : 'SAME AS SMS',
    //       same_as   : 'sms'
    //     }
    //   }
    //
    //   if (this.audit_main_types[0].enabled === true && this.audit_main_types[1].enabled === true && this.audit_main_types[2].enabled === true && this.activeAuditType === 'isps') {
    //     return {
    //       type      : 'isps',
    //       text      : 'SAME AS SMS',
    //       same_as   : 'sms'
    //     }
    //   }
    //
    //   if (this.audit_main_types[0].enabled === true && this.audit_main_types[1].enabled === true && this.audit_main_types[2].enabled === true && this.activeAuditType === 'cyber') {
    //     if (this.newCompanyAudit.isps.same_in.enabled === true) {
    //       return {
    //         type      : 'cyber',
    //         text      : 'SAME AS SMS AND ISPS',
    //         same_as   : 'isps'
    //       }
    //     }
    //     return {
    //       type      : 'cyber',
    //       text      : null,
    //       selection : [
    //         {
    //           text : 'SAME AS SMS',
    //           same_as : 'sms',
    //         },
    //         {
    //           text : 'SAME AS ISPS',
    //           same_as : 'isps',
    //         }
    //       ]
    //     }
    //   }
    //
    //   return {
    //     audit_type : null,
    //     type       : null,
    //     text      : null
    //   }
    // },
    getMultiCustomKey() {
      return this.getIndexOfType(this.activeAuditType)
    }
  },
  async created() {
    this.initializeData();
  },
  // watch : {
  //   'newCompanyAudit.sms' : {
  //     handler() {
  //       let newObject = DataService.assignObjectKeysToOtherObject(this.newCompanyAudit.sms, this.keysToAssign);
  //       if (this.newCompanyAudit.isps.same_in.enabled === true && this.newCompanyAudit.isps.same_in.value === 'sms') {
  //         this.newCompanyAudit.isps = DataService.setValuesFromNewObject(this.newCompanyAudit.isps, newObject);
  //       }
  //
  //       if (this.newCompanyAudit.cyber.same_in.enabled === true && this.newCompanyAudit.cyber.same_in.value === 'sms') {
  //         this.newCompanyAudit.cyber = DataService.setValuesFromNewObject(this.newCompanyAudit.cyber, newObject);
  //       }
  //     },
  //     deep: true
  //   },
  //   'newCompanyAudit.isps' : {
  //     handler() {
  //       let newObject = DataService.assignObjectKeysToOtherObject(this.newCompanyAudit.isps, this.keysToAssign);
  //       if (this.newCompanyAudit.cyber.same_in.enabled === true && this.newCompanyAudit.cyber.same_in.value === 'isps') {
  //         this.newCompanyAudit.cyber = DataService.setValuesFromNewObject(this.newCompanyAudit.cyber, newObject);
  //       }
  //     },
  //     deep: true
  //   }
  // }
}
</script>


<template>

  <div class="modal fade" data-backdrop="static" data-keyboard="false" id="ScheduleNewCompanyAuditModal" tabindex="-1">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
      <form class="modal-content" @submit.prevent="createNewAuditRecord">
        <div class="modal-header">
          <div class="modal-title w-100 text-center">
            <h5 class="font-weight-bold">Company Audit Appointment and Plan</h5>
          </div>
        </div>
        <div class="modal-body">
<!--          &lt;!&ndash;   FOR AUDIT TYPE 1 &ndash;&gt;-->
<!--          <div class="form-group row no-gutters mb-1">-->
<!--            <label for="audit_type_1" class="font-weight-bold col-sm-3 col-form-label">Audit Type 1</label>-->
<!--            <div class="form-check form-check-inline">-->
<!--              <input class="form-check-input checkbox-input" type="checkbox" value="" id="audit_type1_sms" @click="setAuditTypeOne(0, 'sms')" :checked="audit_main_types[0].enabled">-->
<!--              <label for="audit_type1_sms" class="font-weight-bold col-m-3 col-form-label no-select cursor-pointer">SMS</label>-->
<!--            </div>-->
<!--            <div class="form-check form-check-inline">-->
<!--              <input class="form-check-input checkbox-input" type="checkbox" value="" id="audit_type1_isps" @click="setAuditTypeOne(1, 'isps')" :checked="audit_main_types[1].enabled">-->
<!--              <label for="audit_type1_isps" class="font-weight-bold col-m-3 col-form-label no-select cursor-pointer">ISPS</label>-->
<!--            </div>-->
<!--            <div class="form-check form-check-inline">-->
<!--              <input class="form-check-input checkbox-input" type="checkbox" value="" id="audit_type1_cyber" @click="setAuditTypeOne(2, 'cyber')" :checked="audit_main_types[2].enabled">-->
<!--              <label for="audit_type1_cyber" class="font-weight-bold col-m-3 col-form-label no-select cursor-pointer">Cyber</label>-->
<!--            </div>-->
<!--          </div>-->

<!--&lt;!&ndash;          NAVIGATION FOR SELECTED AUDIT TYPE&ndash;&gt;-->
<!--          <nav class="nav nav-pills nav-justified mb-2">-->
<!--            <a :class="['gray nav-item nav-link', {'active_custom': activeAuditType === 'sms'}, {'disabled-type' : audit_main_types[0].enabled === false}]" href="#" @click="setActiveAuditType(0)">SMS</a>-->
<!--            <a :class="['gray nav-item nav-link', {'active_custom': activeAuditType === 'isps'}, {'disabled-type' : audit_main_types[1].enabled === false}]" href="#" @click="setActiveAuditType(1)">ISPS</a>-->
<!--            <a :class="['gray nav-item nav-link', {'active_custom': activeAuditType === 'cyber'}, {'disabled-type' : audit_main_types[2].enabled === false}]" href="#" @click="setActiveAuditType(2)">Cyber</a>-->
<!--          </nav>-->


<!--            &lt;!&ndash;   FOR AUDIT TYPE 2 &ndash;&gt;-->
<!--            <div class="form-group row no-gutters mb-1">-->
<!--              <label for="audit_type_1" class="font-weight-bold col-sm-3 col-form-label">Audit Type 2</label>-->
<!--              <div class="form-check form-check-inline">-->
<!--                <input class="form-check-input checkbox-input" type="checkbox" value="" id="audit_type2_annual" @click="setAuditTypeTwo(1)" :disabled="disabledElements" :checked="newCompanyAudit[activeAuditType].audit_type_2.includes(1)">-->
<!--                <label for="audit_type2_annual" class="font-weight-bold col-m-3 col-form-label no-select cursor-pointer">Annual</label>-->
<!--              </div>-->
<!--              <div class="form-check form-check-inline">-->
<!--                <input class="form-check-input checkbox-input" type="checkbox" value="" id="audit_type2_occasional" @click="setAuditTypeTwo(2)" :disabled="disabledElements" :checked="newCompanyAudit[activeAuditType].audit_type_2.includes(2)">-->
<!--                <label for="audit_type2_occasional" class="font-weight-bold col-m-3 col-form-label no-select cursor-pointer">Occasional</label>-->
<!--              </div>-->
<!--              <div class="form-check form-check-inline">-->
<!--                <input class="form-check-input checkbox-input" type="checkbox" value="" id="audit_type2_navigational" @click="setAuditTypeTwo(3)" :disabled="disabledElements" :checked="newCompanyAudit[activeAuditType].audit_type_2.includes(3)">-->
<!--                <label for="audit_type2_navigational" class="font-weight-bold col-m-3 col-form-label no-select cursor-pointer">Navigational Audit</label>-->
<!--              </div>-->
<!--            </div>-->

            <!--   FOR Type of Audit -->
            <div class="form-group row no-gutters mb-1">
              <label for="audit_type_1" class="font-weight-bold col-sm-3 col-form-label">Type of Audit</label>
              <div class="form-check form-check-inline">
                <input class="form-check-input checkbox-input" type="checkbox" value="" id="audit_type3_onsite" @click="setAuditTypeThree(1)" :disabled="disabledElements" :checked="newCompanyAudit[activeAuditType].audit_type_3.includes(1)">
                <label for="audit_type3_onsite" class="font-weight-bold col-m-3 col-form-label no-select cursor-pointer">Onsite</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input checkbox-input" type="checkbox" value="" id="audit_type3_remote" @click="setAuditTypeThree(2)" :disabled="disabledElements" :checked="newCompanyAudit[activeAuditType].audit_type_3.includes(2)">
                <label for="audit_type3_remote" class="font-weight-bold col-m-3 col-form-label no-select cursor-pointer">Remote</label>
              </div>
            </div>

          <!--          FOR PERSON RESPONSIBLE  -->
          <div class="form-group row no-gutters mb-1">
            <label for="last_name" class="font-weight-bold col-sm-3 col-form-label">Person Responsible </label>
            <div class="col-sm-9">
              <input type="text" id="last_name" class="form-control form-control-sm" required v-model="newCompanyAudit[activeAuditType].person_responsible">
            </div>
          </div>

            <!--   Date of Audit -->
            <div class="form-group row no-gutters mb-1">
              <label for="date_of_audit" class="font-weight-bold col-sm-3 col-form-label">Date of Audit</label>
              <div class="col-sm-9">
                <date-picker id = "date-picker" :lang = 'en' type="date" valueType="format" range style="width: 100%" placeholder="Select Date of Audit" :editable="false" v-model="newCompanyAudit[activeAuditType].audit_date" :disabled="disabledElements"></date-picker>
              </div>
            </div>

            <!--   Appointment Date  -->
            <div class="form-group row no-gutters mb-1">
              <label for="appointment_date" class="font-weight-bold col-sm-3 col-form-label">Appointment Date</label>
              <div class="col-sm-9">
                <date-picker id = "date-picker" :lang = 'en' type="date" valueType="format" style="width: 100%" placeholder="Select Date of Appointment" :editable="false" v-model="newCompanyAudit[activeAuditType].appointment_date" :disabled="disabledElements"></date-picker>
              </div>
            </div>

          <!--          FOR AUDIT PLACE  -->
          <div class="form-group row no-gutters mb-1">
            <label for="last_name" class="font-weight-bold col-sm-3 col-form-label">Place </label>
            <div class="col-sm-9">
              <input type="text" id="last_name" class="form-control form-control-sm" required v-model="newCompanyAudit[activeAuditType].audit_place">
            </div>
          </div>

            <!--   Lead Auditor  -->
            <div class="form-group row no-gutters mb-1">
              <label for="lead" class="font-weight-bold col-sm-3 col-form-label">Lead</label>
              <div class="col-sm-9">
                <multi-select-with-search
                  :key="activeAuditType + '_lead_' + audit_main_types[getMultiCustomKey].enabled"
                  id="lead-data"
                  :items="leadAndNonLeadAuditors" :entity="'Lead'"
                  @doneSelected="setLeadMembers"
                  :selected="newCompanyAudit[activeAuditType].lead_ids"
                  :disabled="disabledElements"
                >
                </multi-select-with-search>
              </div>
            </div>

            <!--   Auditors  -->
            <div class="form-group row no-gutters mb-1">
              <label for="lead" class="font-weight-bold col-sm-3 col-form-label">Member</label>
              <div class="col-sm-9">
                <multi-select-with-search
                  :key="activeAuditType + '_member_' + audit_main_types[getMultiCustomKey].enabled"
                  id="members-data"
                  :items="leadAndNonLeadAuditors" :entity="'Members'"
                  @doneSelected="setNonLeadMembers"
                  :selected="newCompanyAudit[activeAuditType].members_ids"
                  :disabled="disabledElements"
                >
                </multi-select-with-search>
              </div>
            </div>

            <!--   Extension  -->
            <div class="form-group row no-gutters mb-1">
              <label for="extension" class="font-weight-bold col-sm-3 col-form-label">Extension</label>
              <div class="col-sm-9">
                <select class="form-control form-control-sm text-uppercase" id="internal" v-model="newCompanyAudit[activeAuditType].extension" :disabled="disabledElements" @change="newCompanyAudit[activeAuditType].extension_date = null">
                  <option :value="1">Yes</option>
                  <option :value="0">No</option>
                </select>
              </div>
            </div>

            <!--   Extension Date  -->
            <div class="form-group row no-gutters mb-1" v-if="newCompanyAudit[activeAuditType].extension === 1">
              <label for="extension_date" class="font-weight-bold col-sm-3 col-form-label">Extension Date</label>
              <div class="col-sm-9">
                <input type="date" :min="minimumDate"  class="form-control form-control-sm" v-model="newCompanyAudit[activeAuditType].extension_date" :disabled="disabledElements">
              </div>
            </div>

        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-sm btn-secondary" ref="NewUser_close" @click.prevent="cancelAdd">Close</button>
          <button type="submit" class="btn btn-sm btn-primary" :disabled="noSelectedAuditTypeOne" :class="noSelectedAuditTypeOne ? 'e-btn-blue-disabled' : ''">Create New Record</button>
        </div>
      </form>
    </div>
  </div>




</template>

<style scoped>
.active_custom {
  background-color: #d3313c;
  color: #fff;
}

</style>
